<template>
	<div>
		<div>
			<el-steps align-center :active="1">
				<el-step title="step 1" description="上传"></el-step>
				<el-step title="step 2" description="选择店铺"></el-step>
				<el-step title="step 3" description="提交"></el-step>
			</el-steps>
		</div>
		<div class="uploader">
			<div class="upload-tips">
				<p class="frm-tips">- 压缩包 -</p>
				<p class="frm-tips">每个商品最多可上传8张图片，图片比例1:1</p>
				<p class="frm-tips">然后将所有图片放于一个文件夹并压缩为ZIP格式</p>
				<p class="frm-tips">图片命名格式：商品名+后缀(“-编号”)，如：玫瑰花-0、玫瑰花-1（编号0代表第一张主图）</p>
			</div>

			<div v-if="isup==0" class="upload-wrap">
				<p class="frm-tips">- 上传文件 -</p>
				<el-upload accept=".zip" ref="importUpload" class="upload-demo" drag :http-request="upload" multiple>
					<i class="el-icon-upload"></i>
					<div class="el-upload__text"><em>选择文件上传</em></div>
				</el-upload>
			</div>
			<el-row v-if="isup==1">
				<el-col :sm="24" :lg="24">
					<el-result icon="success" title="数据上传成功，请选择店铺" subTitle="">
						<template slot="extra" v-if="selshop==0">
							<el-button @click="xiayibu" type="primary" size="medium">下一步</el-button>
						</template>
					</el-result>
				</el-col>
			</el-row>
			<div v-if="selshop==1">
				<el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
					@selection-change="handleSelectionChange">
					<el-table-column type="selection" width="55">
					</el-table-column>
					<el-table-column label="渠道" width="120">
						<template slot-scope="scope">{{ scope.row.date }}</template>
					</el-table-column>
					<el-table-column prop="name" label="ID" width="120">
					</el-table-column>
					<el-table-column prop="address" label="店铺名称" show-overflow-tooltip>
					</el-table-column>
				</el-table>

				<el-row style="text-align: right;margin-top: 20px;">
					<el-button @click="quxiao" round>取消</el-button>
					<el-button type="primary" round>确认提交</el-button>
				</el-row>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
	export default {
		data() {
			return {
				currentPage: 1,
				isup: 0,
				tableData: [{
					date: '2016-05-02',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				}, {
					date: '2016-05-04',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1517 弄'
				}, {
					date: '2016-05-01',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1519 弄'
				}, {
					date: '2016-05-03',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1516 弄'
				}],
				selshop: 0
			}
		},
		created() {
			console.log("ddd")
		},
		methods: {
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			xiayibu() {
				this.selshop = 1
			},
			quxiao(){
				this.$router.go(0)
			},
			upload(param) {
				var importUpload = this.$refs.importUpload;
				const formData = new FormData();
				formData.append('Filedata', param.file)
				formData.append('token', "1f9e6cb4759d7e93d38280388a5dd120")
				const url = 'http://www.vssite.com/tools/upload_ajax.ashx?action=UpLoadFile'
				axios.post(url, formData).then(data => {
					setTimeout(function() {
						importUpload.clearFiles()
					}, 1000)
					this.$notify({
						title: '成功',
						message: '文件上传成功',
						type: 'success'
					});
					this.isup = 1;
					console.log('上传图片成功')
				}).catch(response => {
					console.log('图片上传失败')
				})
			}
		}
	}
</script>

<style>
	.uploader {
		margin: 0 auto;
		padding-top: 0;
		text-align: center;
		font-size: 14px;
		padding-left: 10px;
	}

	.import-steps,
	.upload-tips {
		margin-top: 30px;
	}

	.upload-tips {
		margin-top: 30px;
		text-align: center;
	}

	.frm-tips {
		width: auto;
		color: #8d8d8d;
		font-size: 14px;
		line-height: 2;
	}

	.upload-wrap {
		position: relative;
		display: block;
		margin: 0 auto;
		margin-top: 30px;
		margin-bottom: 30px;
		width: 600px;
		vertical-align: top;
		text-align: center;
		font-size: 14px;
		color: #999;
	}

	.upload-wrap a {
		display: block;
		box-sizing: border-box;
		margin-top: 10px;
		padding: 20px 0;
		border: 2px dotted #E7E7E7;
		border-radius: 10px;
		color: #999;
		text-align: center;
		text-decoration: none;
	}

	.webuploader-container {
		position: relative;
		display: block;
	}
</style>
